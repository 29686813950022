import {
  FormControl,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  Theme,
} from '@mui/material';
import { alpha, MUIStyledCommonProps, styled } from '@mui/system';
import { StyledComponent } from '@mui/styled-engine';
import { SelectProps } from './Select.types';

function getColor(
  disabledFlag: boolean,
  errorFlag: boolean,
  disabledColor: string,
  errorColor: string,
  color: string,
  fallback?: string,
) {
  if (disabledFlag) return disabledColor;
  if (errorFlag) return errorColor;
  if (color) return color;
  return fallback;
}

export const StyledHelperText = styled(FormHelperText)(
  ({ theme, disabled }: { theme: Theme; disabled: boolean }) => ({
    '&.Mui-disabled': {
      color: disabled && theme.palette.grey[200],
    },
  }),
);

export const StyledSelect: StyledComponent<
  SelectProps & {
    theme?: Theme;
    color: string;
    error: boolean;
    disabled: boolean;
  },
  SelectProps,
  any
> = styled(Select)(({ theme, color, error, disabled }) => ({
  fieldset: {
    border: 0,
  },
  '.MuiSelect-select': {
    fontSize: theme.spacing(4),
    lineHeight: 1,
    minHeight: 'auto !important',
    padding: theme.spacing(5, 3, 1),
    borderRadius: theme.spacing(2),
    border: 2,
    borderStyle: 'solid',
    borderColor: getColor(
      disabled,
      error,
      theme.palette.grey[300],
      theme.palette.error.main,
      theme.palette.grey[200],
    ),
  },
  '&.Mui-focused': {
    '.MuiSelect-select': {
      borderRadius: theme.spacing(2),
      borderColor: getColor(
        disabled,
        error,
        theme.palette.grey[300],
        theme.palette.error.main,
        theme.palette?.[color]?.main,
        theme.palette.primary.main,
      ),
    },
    '.MuiSvgIcon-root': {
      color: getColor(
        disabled,
        error,
        theme.palette.grey[200],
        theme.palette.error.main,
        theme.palette?.[color]?.main,
        theme.palette.primary.main,
      ),
    },
  },
}));

export const StyledFormControl = styled(FormControl)();

export const StyledLabel: StyledComponent<
  InputLabelProps & MUIStyledCommonProps<Theme>,
  any,
  { value: string }
> = styled(InputLabel)(({ theme }: { theme: Theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.grey[200],
  },
  '&.MuiInputLabel-root': {
    fontSize: theme.spacing(4),
    transform: `translate(${theme.spacing(3)}, ${theme.spacing(1.6)}) scale(1)`,
    color: theme.palette.grey[400],
  },
  '&.MuiInputLabel-shrink': {
    transform: `translate(${theme.spacing(3)}, 0) scale(0.75)`,
  },
}));

export const StyledMenuItem = styled(MenuItem)(
  ({
    theme,
    color,
    error,
    disabled,
  }: {
    theme?: Theme;
    color: 'primary' | 'secondary' | 'success' | 'warning' | 'info' | 'error';
    error: boolean;
    disabled: boolean;
  }) => ({
    fontSize: theme.spacing(4),
    minHeight: theme.spacing(8),
    maxHeight: theme.spacing(8),

    '.MuiTouchRipple-child, .MuiTouchRipple-child:before': {
      backgroundColor: getColor(
        disabled,
        error,
        theme.palette.grey[100],
        theme.palette.error.dark,
        theme.palette?.[color]?.dark,
        theme.palette.primary.dark,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getColor(
        disabled,
        error,
        theme.palette.grey[100],
        alpha(theme.palette.error.main, 0.42),
        color && alpha(theme.palette?.[color]?.main, 0.42),
        alpha(theme.palette.primary.main, 0.42),
      ),
      '&:hover, &.Mui-focusVisible': {
        backgroundColor: getColor(
          disabled,
          error,
          theme.palette.grey[100],
          alpha(theme.palette.error.light, 0.42),
          color && alpha(theme.palette?.[color]?.light, 0.42),
          alpha(theme.palette.primary.light, 0.42),
        ),
      },
    },
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: getColor(
        disabled,
        error,
        theme.palette.grey[100],
        alpha(theme.palette.error.light, 0.42),
        color && alpha(theme.palette?.[color]?.light, 0.42),
        alpha(theme.palette.primary.light, 0.42),
      ),
    },
  }),
);
