import { useState } from 'react';
import {
  StyledFormControl,
  StyledHelperText,
  StyledLabel,
  StyledMenuItem,
  StyledSelect,
} from './Select.styles';
import type { SelectProps } from './Select.types';
import { OptionType } from './Select.types';

export function Select(props: SelectProps): JSX.Element {
  const {
    options,
    placeholder,
    onChange,
    color,
    error,
    disabled,
    value: selectedValue = '',
  } = props;
  const [innerValue, setInnerValue] = useState(selectedValue);
  const isOptionString = typeof options[0] === 'string';

  return (
    <StyledFormControl color={color} error={error} disabled={disabled}>
      <StyledLabel color={color} error={error} value={innerValue as string}>
        {props.label}
      </StyledLabel>
      <StyledSelect
        {...props}
        disabled={disabled}
        error={error}
        color={color}
        MenuProps={{
          elevation: 0,
          color,
          sx: {
            '.MuiPaper-root': {
              border: 2,
              borderStyle: 'solid',
              borderColor: (theme) =>
                error
                  ? theme.palette.error.main
                  : theme.palette[color || 'primary'].main,
              borderRadius: (theme) => theme.spacing(2),
            },
          },
        }}
        onChange={(event) => {
          setInnerValue(event.target.value);
          onChange(event, event.target);
        }}
        value={innerValue}
        renderValue={(selected: Array<string>) => {
          if (!selected?.length) {
            return placeholder;
          }
          if (Array.isArray(selected)) {
            return selected?.join?.(', ');
          }

          const [option] = isOptionString
            ? (options as string[]).filter((opt) => opt === selected)
            : (options as OptionType[]).filter((opt) => opt.value === selected);
          return isOptionString ? option : (option as OptionType).label;
        }}
      >
        {isOptionString
          ? (options as string[]).map((option) => (
              <StyledMenuItem
                key={option}
                value={option}
                color={color}
                error={error}
                disabled={disabled}
              >
                {option}
              </StyledMenuItem>
            ))
          : (options as OptionType[]).map((option) => (
              <StyledMenuItem
                key={option.value}
                value={option.value}
                color={color}
                error={error}
                disabled={disabled}
              >
                {option.label}
              </StyledMenuItem>
            ))}
      </StyledSelect>
      {!!props.helperText && (
        <StyledHelperText
          disabled={disabled}
          color={error ? 'error' : color && 'primary'}
        >
          {props.helperText}
        </StyledHelperText>
      )}
    </StyledFormControl>
  );
}
